<template>
<Onboardlayout>
  <template v-slot:header>
    <Header>
      <template #right-header-component>
        <div></div>
      </template>
    </Header>
  </template>
  <template v-slot:main-content>
    <Notification
       :inner-title="text"
        :title="title">
      <template v-slot:icon>
        <checked-icon/>
      </template>
    </Notification>
  </template>
</Onboardlayout>
</template>

<script>
import Onboardlayout from "@/components/onboarding/layouts/Onboardlayout";

import Header from "@/components/onboarding/reuseables/Header";
import Notification from "@/components/onboarding/reuseables/Notification";
import CheckedIcon from "@/components/onboarding/reuseables/checkedIcon";
export default {
name: "VerifyNotification",
  components: {CheckedIcon, Notification, Header, Onboardlayout},
  data: ()=> {
  return {
    title: "Check your Email to Verify \nyour Account",
    text: "Your account has been created successfully.  A verification  \nlink has been sent to your registered email address."
  }
  }
}
</script>

<style scoped>
.inner-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "DM Sans", serif;
  line-height: 31px;

}
.inner-title{
  text-align: center;
  color: #828282;
  font-size: 16px;
  padding: 1rem;

}

</style>
