<template>
  <div style="margin: 10rem auto">
  <div class="inner-content">
    <div class="mb-11">
      <slot name="icon"></slot>
    </div>
    <h3 class="tw-font-black tw-text-xl mb-4 main-title" style="font-size: 24px">{{title}}</h3>
    <p class="inner-title">{{innerTitle}}</p>
  </div>
  </div>
</template>

<script>
export default {
name: "Notification",
  props: {
  title: String,
    innerTitle: String

  }
}
</script>

<style scoped lang="scss">
.inner-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "DM Sans", serif;
  line-height: 31px;

}
.main-title{
  text-align: center;
  @media (min-width: 650px) {
    white-space: pre;
  }
}
.inner-title{
  text-align: center;
  color: #828282;
  font-size: 16px;
  padding: 1rem;
  @media (min-width: 650px) {
    white-space: pre;

  }

}

</style>
